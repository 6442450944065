<template>
    <div id="app">
        <vue-headful
                title="Lean2Grow | Consultoria e Serviços"
                description="O nosso trabalho esta assente nos principios de Mutualiy, Enablement & Growth. Assim,
                focamos em solucoes mutuamente beneficas, intervimos como catalizadores e facilitadores, sempre focados
                no crescimento sustentavel dos negocios dos nossos clientes."
        />

        <!-- Preloader Start -->
        <div id="preloader-active">
            <div class="preloader d-flex align-items-center justify-content-center">
                <div class="preloader-inner position-relative">
                    <div class="preloader-circle"></div>
                    <div class="preloader-img pere-text">
                        <img src="./assets/logo.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- Preloader Start -->

        <Header></Header>
        <!--Render routers-->
        <router-view></router-view>
        <Footer></Footer>

        <!--Go to Top Button-->
        <a id="scrollUp"
           href="#app"
           style="position: fixed; z-index: 2147483647; display: none">
            <i class="fas fa-arrow-up"></i>
        </a>

        <notifications group="sendEmail" />
    </div>
</template>

<script>

    import Footer from './components/Footer'
    import Header from './components/Header'

    // jquery
    import $ from 'jquery'

    export default {
        name: 'App',
        components: {
            Footer,
            Header
        },
        methods: {},
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Home',
            // all titles will be injected into this template
            titleTemplate: '%s | Lean2Grow',
            meta: [
                {
                    name: 'description',
                    content: 'Somos uma empresa Moçambicana jovem e dinâmica, vocacionada ao fortalecimento da ' +
                        'capacidade e produtividade de lideres e organizações, através da prestação de serviços ' +
                        'de consultoria de Negócios, Recursos Humanos, Desenvolvimento Organizacional e Coaching ' +
                        'Executivo & de Negocio.'
                }
            ]
        },
        mounted() {

            $(window).on('scroll', () => {
                hideGoTopButton();
                canStickyMenu();
            });

            var header = document.getElementById("myHeader");

            var scrollUp = document.getElementById("scrollUp");

            var sticky = header.offsetTop;

            function hideGoTopButton() {
                if (window.pageYOffset === 0) {
                    scrollUp.style.display = "none"
                } else {
                    scrollUp.style.display = "block";
                }
            }

            function canStickyMenu() {
                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky", "animated", "fadeInDown", "faster");
                } else {
                    header.classList.remove("sticky", "animated", "fadeInDown", "faster");
                    header.classList.add("animated", "fadeInUp", "faster");
                }
            }

            $(window).on('load', () => {
                $('#preloader-active').delay(500).fadeOut('slow');
                $('body').delay(450).css({
                    'overflow': 'visible'
                });
            })

        }
    }
</script>

<style>
    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
    }
</style>
