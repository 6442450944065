<template>
  <div>
    <!-- Header Start -->
    <div class="header-area">
      <div class="main-header ">
        <div class="header-top top-bg d-none d-lg-block">
          <div class="container-fluid">
            <div class="col-xl-12">
              <div class="row d-flex justify-content-between align-items-center">
                <div class="header-info-left">
                  <ul>
                    <li><i class="fas fa-map-marker-alt"></i>
                      Triunfo Novo - Rua 45080 - N°6 - Maputo
                    </li>
                    <li><i class="fas fa-envelope"></i>info@lean2grow.com</li>
                  </ul>
                </div>

                <div class="header-info-right">
                  <ul class="header-social">
                    <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myHeader" class="header-bottom  header-sticky">
          <div class="container-fluid">
            <div class="row align-items-center">
              <!-- Logo -->
              <div class="col-xl-2 col-6 col-sm-6 col-lg-1 col-md-1">
                <div class="logo">
                  <router-link to="/"><img src="../assets/logo.png" alt="Lean2Grow Logo">
                  </router-link>
                </div>
              </div>

              <div class="col-xl-6 p-0 col-6 col-sm-6 col-lg-11 col-md-11 navbar navbar-fixed-top d-lg-none">
                <ul style="color: #f44a40 !important;" class="nav navbar-nav p-0 ml-auto">
                                    <span v-on:click="showMobileMenu">
                                        <i :class="classIcon"></i>
                                    </span>
                </ul>
              </div>

              <div id="navigation-mobile"
                   class="d-lg-none col-11 m-0 wow animated fadeInDownBig"
                   data-wow-duration=".3s"
                   :class="classMobileMenu">

                <router-link v-on:click.native="goToTop" to="/">Home</router-link>
                <router-link v-on:click.native="goToTop" to="/sobre">Sobre Nós</router-link>
                <router-link v-on:click.native="goToTop" to="/servicos">Serviços</router-link>
                <router-link v-on:click.native="goToTop" to="/portfolio">Portfólio</router-link>
                <a href="#contact" v-on:click="goToTop2">Contacte-nos</a>
                <!--                                <li><a href="/"><i class="flag-icon flag-icon-us"></i></a></li>-->
              </div>

              <div class="col-xl-10 col-lg-10 col-md-10">
                <!-- Main-menu -->
                <div class="main-menu f-right d-none d-lg-block">
                  <nav>
                    <ul id="navigation">
                      <li>
                        <router-link v-on:click.native="goToTop" to="/">Home</router-link>
                      </li>
                      <li>
                        <router-link v-on:click.native="goToTop" to="/sobre">Sobre Nós</router-link>
                      </li>
                      <li>
                        <router-link v-on:click.native="goToTop" to="/servicos">Serviços</router-link>
                      </li>
                      <li>
                        <router-link v-on:click.native="goToTop" to="/portfolio">Portfólio</router-link>
                      </li>
                      <li @click="accaoContacte"><a href="#contact" >Contacte-nos</a></li>
                      <!--                                            <li><a href="/"><i class="flag-icon flag-icon-us"></i></a>-->
                      <!--                                                <ul class="submenu">-->
                      <!--                                                    <li><a href="/"><i class="flag-icon flag-icon-pt"></i></a></li>-->
                      <!--                                                </ul>-->
                      <!--                                            </li>-->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <!-- Header End -->
  </div>
</template>

<script>

export default {
  name: "Header",
  data() {
    return {
      classMobileMenu: 'd-none',
      classIcon: 'fas fa-bars fa-2x'
    }
  },
  methods: {
    showMobileMenu() {
      if (this.classMobileMenu === 'd-none') {
        this.classMobileMenu = 'slideInUp';
        this.classIcon = 'fas fa-times fa-2x'
      } else {
        this.classMobileMenu = 'd-none';
        this.classIcon = 'fas fa-bars fa-2x'

      }
    },
    goToTop() {
      this.showMobileMenu();
      this.accaoContacte();
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    goToTop2() {
      this.showMobileMenu();
      this.accaoContacte();
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    accaoContacte() {
      this.$gtag.event('botao-contacte-clicado', {
        'event_category': 'contactos',
        'event_label': 'Botao contacto clicado',
        'value': 1
        })
    },
  }
}
</script>

<style scoped>
#navigation-mobile {
  background: #ffffff;
  position: fixed;
  top: 4em;
  margin: auto;
}

#navigation-mobile a {
  padding: 20px 20px;
  font-size: 14px;
  font-weight: 400;
  color: #0b1c39;
  text-transform: capitalize;
  display: block;
}

#navigation-mobile a:hover {
  color: #f44a40;
}
</style>
