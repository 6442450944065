<template>
    <footer>

        <!-- Request Back Start -->
        <div id="contact" class="request-back-area section-padding30">
            <div class="container">
                <div class="row d-flex justify-content-between">
                    <div class="col-xl-4 col-lg-5 col-md-5">
                        <div class="request-content">
                            <h3>Entrar em contacto</h3>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7">
                        <!-- Contact form Start -->
                        <div class="form-wrapper">
                            <form id="contact-form" @submit.prevent="sendEmail">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-box  mb-30">
                                            <input type="text" name="name" placeholder="Name" v-model="UserData.name"
                                                   required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-box mb-30">
                                            <input type="number" name="phone" placeholder="Phone"
                                                   v-model="UserData.phone" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-box mb-30">
                                            <input type="email" name="email" placeholder="Email"
                                                   v-model="UserData.email" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 mb-30">
                                        <textarea class="text-area form-control" rows="4"
                                                  v-model="UserData.message" required></textarea>
                                    </div>
                                    <div class="offset-8 col-lg-4 col-md-4">
                                        <button
                                            type="submit"
                                            class="send-btn"
                                        @click="accaoEnviar">Enviar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>     <!-- Contact form End -->
                </div>
            </div>
        </div>
        <!-- Request Back End -->

        <!-- Footer Start-->
        <div class="footer-area footer-padding">
            <div class="container">
                <div class="row d-flex justify-content-between">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        <div class="single-footer-caption mb-50">
                            <div class="single-footer-caption mb-30">
                                <!-- logo -->
                                <div class="footer-logo">
                                    <a href="#"><img src="../assets/logo.png" alt="Lean2Grow logo"></a>
                                </div>
                                <div class="footer-tittle">
                                    <div class="footer-pera">
                                        <p>Ajudamos líderes e empresas a elevarem a sua produtividade</p>
                                    </div>
                                </div>
                                <!-- social -->
                                <div class="footer-social">
                                    <a href="#"><i class="fab fa-facebook-square"></i></a>
                                    <a href="#"><i class="fab fa-twitter-square"></i></a>
                                    <a href="#"><i class="fab fa-linkedin"></i></a>
                                    <a href="#"><i class="fab fa-pinterest-square"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-5">
                        <div class="single-footer-caption mb-50">
                            <div class="footer-tittle">
                                <h4>Mapa</h4>
                                <ul>
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#">Sobre Nós</a></li>
                                    <li><a href="#">Serviços</a></li>
                                    <li><a href="#">Contacte-nos</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-7">
                        <div class="single-footer-caption mb-50">
                            <div class="footer-tittle">
                                <h4>Serviços</h4>
                                <ul>
                                    <li><a href="#">Gestão Estratégica de Capital Humano</a></li>
                                    <li><a href="#">Desenvolvimento Organizacional</a></li>
                                    <li><a href="#">Formação</a></li>
                                    <li><a href="#">Coaching Executivo & de Negocios</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5">
                        <div class="single-footer-caption mb-50">
                            <div class="footer-tittle">
                                <h4>Entre em Contacto</h4>
                                <ul>
                                    <li><a href="#">+258 82 310 2866</a></li>
                                    <li><a href="#">info@lean2grow.com</a></li>
                                    <li><a href="#">Triunfo Novo - Rua 45080 - N°6 - Maputo</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer-bottom aera -->
        <div class="footer-bottom-area footer-bg">
            <div class="container">
                <div class="footer-border">
                    <div class="row d-flex align-items-center">
                        <div class="col-xl-12 ">
                            <div class="footer-copy-right text-center">
                                <p>
                                    Copyright &copy; 2021 All rights reserved | This website is made with <i
                                        class="fas fa-heart" aria-hidden="true"></i> by <a
                                        href="https://www.linkedin.com/in/claudiosumburane/" target="_blank">Claudio
                                    Sumburane</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer End-->
    </footer>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Footer",
        data() {
            return {
                UserData: {
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                }
            }
        },
        methods: {
          accaoEnviar() {
            this.$gtag.event('botao-enviar-clicado', {
              'event_category': 'contactos',
              'event_label': 'Botao Enviar email clicado',
              'value': 1
            })
          },
            sendEmail() {
                axios.post('https://scripts.lean2grow.com/mailSender.php',
                    this.UserData)
                    .then(response => {
                        if (response.data === 'sent') {
                            this.$notify({
                                group: 'sendEmail',
                                title: 'O teu Email foi enviado',
                                text: 'Obrigado pela atenção!',
                                position: 'top center',
                                width: '500',
                                color: 'success'
                            });
                            this.UserData.name = '';
                            this.UserData.email = '';
                            this.UserData.phone = '';
                            this.UserData.message = '';
                            // redirect
                        }

                        this.$router.go(-1);
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        }
    }
</script>

<style scoped>

</style>
