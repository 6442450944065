import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import vueHeadful from 'vue-headful';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification'
import VueGtag from 'vue-gtag'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import 'animate.css'
import 'flat-icons/ecommerce.css'
import 'flat-icons/interface.css'
import 'flat-icons/technology.css'
import 'flat-icons/creative.css'
import '@fortawesome/fontawesome-free/css/all.css'

import './assets/css/style.css'

//js libraries
import WOW from 'wowjs/dist/wow.min'
import routes from "./routes";

Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(Meta);
Vue.component('vue-headful', vueHeadful);
Vue.use(Notifications);

Vue.use(VueGtag, {
    config: { id: "G-9CPD2E5FMP" }
});

Vue.config.productionTip = false;

new Vue({
    created() {
        new WOW.WOW({
            live: false
        }).init();
    },
    router: new VueRouter(routes),
    render: h => h(App),
}).$mount('#app')
