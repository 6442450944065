export default {
    mode: 'history',

    routes: [
        {
            path: '/',
            component: () => import('./components/Main'),
            meta: {
                title: 'Home',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Somos uma empresa Moçambicana jovem e dinâmica, vocacionada ao fortalecimento da ' +
                            'capacidade e produtividade de lideres e organizações, através da prestação de serviços ' +
                            'de consultoria de Negócios, Recursos Humanos, Desenvolvimento Organizacional e Coaching ' +
                            'Executivo & de Negocio.'
                    },
                    {
                        property: 'og:description',
                        content: 'Página Home da Lean2Grow'
                    }
                ]
            }
        },
        {
            path: '/sobre',
            component: () => import('./components/About/About'),
            meta: {
                title: 'Sobre Nós',
                metaTags: [
                    {
                        name: 'description',
                        content: 'O nosso trabalho esta assente nos principios de Mutualiy, Enablement & Growth. ' +
                            'Assim, focamos em solucoes mutuamente beneficas,...'
                    },
                    {
                        property: 'og:description',
                        content: 'Página Sobre da Lean2Grow'
                    }
                ]
            }
        },
        {
            path: '/servicos',
            component: () => import('./components/Services/Services'),
            meta: {
                title: 'Serviços',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Na Lean2Grow, somos particularmente competentes nas áreas de Gestão Estratégica de ' +
                            'Capital Humano, Formação, Desenvolvimento Organizacional,...'
                    },
                    {
                        property: 'og:description',
                        content: 'Página serviços da Lean2Grow'
                    }
                ]
            }
        },
        {
            path: '/portfolio',
            component: () => import('./components/Portfolio/Portfolio'),
            meta: {
                title: 'Portfólio',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Na Lean2Grow, somos particularmente competentes nas áreas de Gestão Estratégica de ' +
                            'Capital Humano, Formação, Desenvolvimento Organizacional,...'
                    },
                    {
                        property: 'og:description',
                        content: 'Página portfólio da Lean2Grow'
                    }
                ]
            }
        },

        {
            path: '*',
            alias: '/components/NotFoundPage',
            component: () => import('./components/NotFoundPage'),
        }
    ],
};
